import { useTranslation } from 'next-i18next'
import Image from 'next/image'

import { Card } from '~/components/ui/card'
import { SectionTitle } from '~/domains/home/components/section-title'
import { SectionWrapper } from '~/domains/home/components/section-wrapper'

const ReviewCard = ({ index }: { index: number }) => {
	const { t } = useTranslation()

	return (
		<Card className='flex flex-col gap-4 border p-6 text-left sm:max-w-[280px]'>
			<header className='flex flex-row items-center gap-4'>
				<Image
					src={`/images/home/user-${index + 1}.png`}
					width={50}
					height={50}
					alt={t(`cwHome:testimonials.reviews.${index}.author`)}
				/>

				<div>
					<p className='font-bold text-sm'>
						{t(`cwHome:testimonials.reviews.${index}.author`)}
					</p>

					<p className='text-sm'>
						{t(`cwHome:testimonials.reviews.${index}.jobTitle`)}
						<br />
						{t(`cwHome:testimonials.reviews.${index}.company`)}
					</p>
				</div>
			</header>

			<div className='flex flex-col gap-8'>
				<p className='text-sm'>
					{t(`cwHome:testimonials.reviews.${index}.body`)}
				</p>
			</div>

			<footer className='mt-auto text-right'>
				<p className='font-bold text-sm'>
					{t(`cwHome:testimonials.reviews.${index}.context`)}
				</p>

				<p className='text-sm'>
					{t(`cwHome:testimonials.reviews.${index}.cocoon`)}
				</p>
			</footer>
		</Card>
	)
}

export const HomeTestimonials = () => {
	return (
		<div className='bg-white'>
			<SectionWrapper>
				<SectionTitle>
					{'Nos clients nous aiment et ils le disent...'}
				</SectionTitle>

				<div className='grid grid-cols-1 gap-8 px-8 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 sm:px-0'>
					{Array.from({ length: 4 }).map((_, i) => (
						// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
						<ReviewCard key={i} index={i} />
					))}
				</div>
			</SectionWrapper>
		</div>
	)
}
